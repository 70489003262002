import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Compliments and Leadership",
  "author": "Christina",
  "date": "2020-01-24T08:05:03.000Z",
  "categories": ["Personal-Professional Growth"],
  "slug": "compliments-and-leadership",
  "draft": false,
  "meta_title": "Compliments and Leadership",
  "cover": "../../images/wp_blog_images/facebook-4688538_1920.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After six years of service, I thought I was a “dirt-bag Airman.” I could not wait to separate from the Air Force. That was until I had a supervisor that focused on the positive in me. He realized I had some challenges when it came to my career field, but realized the unique asset I could be if someone provided a little encouragement and accepted me as outside the norm.`}</p>
    <p>{`For the two years that I worked for him, directly and indirectly, I grew exponentially as a person, Airman, and parent. I improved processes for six offices, cleared out waiting rooms when asked, and started learning the beauty of networking.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAL/2gAMAwEAAhADEAAAAY1PMtpeNA3/AP/EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMSEBMh/9oACAEBAAEFAnpxMedRl6gqK14//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEiH/2gAIAQMBAT8BjpVH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAQUAAAAAAAAAAAAAAAAAEAABESEx/9oACAEBAAY/Amk2sH//xAAaEAADAQADAAAAAAAAAAAAAAAAAREhMVFh/9oACAEBAAE/IYavcHhORirxYyVdPSI//9oADAMBAAIAAwAAABDUD//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QotR//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/EEAy2//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQWGx/9oACAEBAAE/EDRWdEPMG8a322lEG12CmZTUpgus4FP1iTQE/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "facebook 4688538 1920",
          "title": "facebook 4688538 1920",
          "src": "/static/7e6c2afc2ca9cc0fd706a3e88b441795/eea4a/facebook-4688538_1920.jpg",
          "srcSet": ["/static/7e6c2afc2ca9cc0fd706a3e88b441795/cb69c/facebook-4688538_1920.jpg 320w", "/static/7e6c2afc2ca9cc0fd706a3e88b441795/c08c5/facebook-4688538_1920.jpg 640w", "/static/7e6c2afc2ca9cc0fd706a3e88b441795/eea4a/facebook-4688538_1920.jpg 1280w", "/static/7e6c2afc2ca9cc0fd706a3e88b441795/0f98f/facebook-4688538_1920.jpg 1920w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` Image by Jean-Paul Jandrain from Pixabay`}</p>
    <p>{`At the time, I had no idea how he did this because I was only focused on my tasks. But with a little reflection, I realized he was the first person in my life to see the amazing in me and water it with positivity. He did not care that I was not a naturally bubbly, chatty, easy-going, follower that is common for my career field or life category.`}</p>
    <p>{`Did he ignore any mistakes I made? Heck no. He would correct as needed, but he never waited for an official meeting to correct me or compliment me. He did it as it happened. Whether the compliments or corrections were even or unbalanced, I could not tell you. Because he said his piece at the moment; it seemed to not matter.`}</p>
    <p>{`His presence in the flight made a big difference. Many of us started complimenting each other on the fly-even me. I became a supervisor at the end of my 2 years working for this amazing man. I took his magical ways with me to my next duty station and began my leadership journey- having wonderful relationships with my Airmen.`}</p>
    <p>{`Seeing the positive in people and providing compliments is an effective way to tell them where they are going right. We all need a little reassurance every now and then. It helps us feed those positive aspects of ourselves, increases self-esteem and self-worth, and softens the blow when the challenges and mistakes happen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      